import axios from "axios"
import store from "@/store"

const req = async (reqName, method, param) => {
  const auth = store.getters.user
  try {
    const response = await axios({
      responseType: 'json',
      method: method,
      url: `${store.getters.conf.apiURL}${store.state.uri[reqName]}`,
      data: {
        ...auth,
        ...param,
      },
    })
    return response
  } catch (error) {
    store.commit('error', {type: 'error', name: 'API', msg: error.toString()})
    return {data: {code: 0}}
  }
}

export default {
  request: (reqName, method, param) => {
    return req(reqName, method, param)
  },
  get: (reqName, param) => {
    return req(reqName, "get", param)
  },
  post: (reqName, param) => {
    return req(reqName, "post", param)
  },
  del: (reqName, param) => {
    return req(reqName, "delete", param)
  },
}
