<template>
  <v-app id="inspire" class="gp-full-height">
    <v-navigation-drawer v-model="drawer" app style="background-color: var(--color-it-gray-light); height: 100%">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6 mt-4">Меню</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list dense nav>
        <v-list-item v-for="item in menu" :key="item.title" :to="item.to" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block outlined class="mb-4" color="var(--color-red)" @click.stop="refreshApp" v-if="snackWithButtons">
            <v-icon left>mdi-cellphone-arrow-down</v-icon>
            Обновить
          </v-btn>
          <v-btn block @click="logout">Выйти</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="var(--color-it-gray-light)">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>{{ appTitleText }}</v-app-bar-title>

      <v-spacer></v-spacer>

      <v-dialog v-model="dialogUpdate" persistent max-width="400" v-if="snackWithButtons">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="var(--color-red)" v-bind="attrs" v-on="on" outlined>
            <v-icon left>mdi-cellphone-arrow-down</v-icon>
            Обновить
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">Доступно обновление</v-card-title>
          <v-card-text>Для коректной работы приложения вам нужно его обновить.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="var(--color-brand-blue)" text @click="refreshApp">Обновить</v-btn>
            <v-btn color="var(--color-brand-gray-dark)" text @click="dialogUpdate = false">Не сейчас</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

  </v-app>
</template>


<script>
import debounce from 'lodash/debounce'
import {mapActions, mapState, mapGetters} from 'vuex'

export default {
  props: {
    source: String,
  },

  data: () => ({
    drawer: null,
    item: {},
    items: [
      {title: 'Общая информация', icon: 'mdi-view-dashboard', to: '/'},
      {title: 'Мои услуги', icon: 'mdi-account-wrench', to: '/services'},
      {title: 'Соц.значимые ресурсы', icon: 'mdi-earth', to: '/socials'},
    ],
    summ: 0,
    dialogUpdate: false,
    refreshing: false,
    registration: null,
    snackBtnText: '',
    //snackWithBtnText: '',
    snackWithButtons: false,
  }),
  created() {
    console.log('created')
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('serviceWorkerUpdateEvent', this.showRefreshUI, { once: true })
    //document.addEventListener('serviceWorkerUpdateEvent', this.showRefreshUI, {})
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        console.log('Application update.')
        window.location.reload()
      })
    }
  },
  mounted() {
    this.setViewHeight()
    const debouncedSetHeight = debounce(this.setViewHeight, 50)
    window.addEventListener('resize', debouncedSetHeight)
    this.$once('destroyed', () => {
      window.removeEventListener('resize', debouncedSetHeight)
    })

    //console.log('Created LayoutMain and ws - '+this.$store.state.wsURL)
    this.wsCreateConnection()
    this.getProfileFromApi()
  },
  methods: {
    ...mapActions(["getProfileFromApi"]),
    showRefreshUI(e) {
      // Display a button inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      //console.log('showRefreshUI')
      //console.log(e)
      //console.log('showRefreshUI')
      //this.snackWithBtnText = 'New version available!'
      this.registration = e.detail
      this.snackWithButtons = true
    },
    refreshApp() {
      //console.log('refreshApp')
      this.snackWithButtons = false
      this.dialogUpdate = false
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return }
      this.registration.waiting.postMessage('skipWaiting')
    },
    setViewHeight() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    wsCreateConnection() {
      if ((this.ws == null)&&(this.$store.state.loggedIn)) {
        if (this.$store.getters.conf.wsURL != '') {
          var vm = this
          //console.log('Create ws')
          this.ws = new WebSocket(this.$store.state.wsURL)
          this.ws.onmessage = function(event) {
            if (event.data == 'login') {
              vm.ws.send('login|' + vm.$store.state.login)
            } else {
              if (event.data != 'OK') {
                let arr = event.data.split('^')
                if (arr[0] == 'event') {
                  vm.$store.commit('receiveEvent', { evTime: arr[1], evOwner: arr[2], evDir: arr[3] })
                } else {
                  vm.$store.commit('receiveMessage', event.data)
                }
              }
            }
          }
        }
      }
    },
    logout() {
      this.$store.commit('loggedOut')
      this.$router.push({ name: 'Login'})
    },
  },
  computed: {
    ...mapState(["menu"]),
    appTitleText() {
      return this.$store.state.title != "" ? this.$store.state.title : "Телецентр ЛК"
    },
  },
}
</script>

<style scoped>

::v-deep .v-app-bar-title__content  {
    overflow: initial;
    text-overflow: clip;
}

.gp-full-height ::v-deep .v-application--wrap {
   min-height: 100vh;
   min-height: calc(var(--vh, 1vh) * 100);
}

::v-deep .v-list .v-list-item--active{
  background-color: var(--color-it-blue);
  color: var(--color-it-white);
}

</style>
