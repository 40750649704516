import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'Login', auth: false },
    component: () => import('../components/LoginForm.vue')
  },
  {
    path: '/main',
    name: 'Main',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/MainForm.vue')
  },
  {
    path: '/services/:c_idx',
    name: 'Services',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/ServicesForm.vue'),
    props: true
  },
  {
    path: '/socials',
    name: 'Socials',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/SocialLinks.vue')
  },
  {
    path: '*',
    redirect: '/login',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
//  console.log(store)
  if (to.matched.some(record => record.meta.auth)) {
    if (!store.state.loggedIn) {
      //console.log(localStorage)
      if (localStorage.getItem("ivlk_session") !== null) {
        const lk_ses = JSON.parse(localStorage.getItem("ivlk_session"))
        store.commit("loggedIn", {
          login:   lk_ses.login,
          token:   lk_ses.token,
        })
      }
    }
    if (store.state.loggedIn) {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})

export default router
