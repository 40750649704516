import Vue from "vue"
import Vuex from "vuex"
import req from "@/plugins/requests"
import router from "@/router"

Vue.use(Vuex, req)

export default new Vuex.Store({
  state: {
    conf_dev: {
      apiURL: "http://localhost:8002/lkapi/",
//      apiURL: "https://lk.inverstel.ru/lkapi/",
      wsURL: "",
      //wsURL: "wss://lk.inverstel.ru/ws/",
    },
    conf_prod: {
      apiURL: "https://lk.inverstel.ru/lkapi/",
      wsURL: "",
      //wsURL: "wss://lk.inverstel.ru/ws/",
    },
    uri: {
      auth: "auth",
      profile: "profile",
      accounts: "accounts",
      groups: "groups/getlist",
      groupsAdd: "groups/add",
      groupsUpdate: "groups/save",
    },
    loggedIn: false,
    login: "",
    token: "",
    loadingProfile: true,
    profile: {},
    contracts: [],
    error: "",
    cards: [],
    menu: [],
    menu_before: [
      {title: 'Общая информация', icon: 'mdi-view-dashboard', to: '/'},
    ],
    menu_after: [
      {title: 'Соц.значимые ресурсы', icon: 'mdi-earth', to: '/socials'},
    ],
  },
  getters: {
    conf(state) {
      if (process.env.NODE_ENV === "production") {
        return state.conf_prod
      } else {
        return state.conf_dev
      }
    },
    user(state) {
      if (state.loggedIn) {
        return {
          login: state.login,
          token: state.token,
        }
      } else {
        return null
      }
    },
  },
  mutations: {
    error(state, err) {
      state.error = `${err.name}: ${err.message}`
    },
    loggedIn(state, prm) {
      state.loggedIn = true
      localStorage.setItem("ivlk_session", JSON.stringify({
        login:   prm.login,
        token:   prm.token,
      }))
      state.login = prm.login
      state.token = prm.token
    },
    loggedOut(state) {
      state.loggedIn = false
      localStorage.removeItem("ivlk_session")
      state.login = ""
      state.token = ""
    },
    genMenu(state) {
      //if (state.menu.length == 0) {
      state.menu.splice(0)
      for (let menu_item of state.menu_before) {
        state.menu.push(menu_item)
      }
      let i = 0
      for (let contr of state.contracts) {
        //let menu_item = {title: `Л.С: ${contr.cid}`, icon: 'mdi-account-wrench', to: `/services/${contr.cid}`}
        let menu_item = {title: `Л/С: ${contr.cid}`, icon: 'mdi-account-wrench', to: `/services/${i}`}
        state.menu.push(menu_item)
        i++
      }
      for (let menu_item of state.menu_after) {
        state.menu.push(menu_item)
      }
      //}
    },
    genCards(state) {
      state.cards.splice(0)
      state.cards.push({title: 'На главную', link: '/main', icon: 'mdi-currency-rub', color: '#a31111', main: false})
      let i = 0
      for (let contr of state.contracts) {
        let card_item = {title: `Л/С: ${contr.cid}`, link: `/services/${i}`, icon: 'mdi-account-wrench', color: '#107ed9', main: true}
        state.cards.push(card_item)
        i++
      }
      state.cards.push({title: 'Социально-значимые ресурсы', link: '/socials', icon: 'mdi-earth', color: '#23720d', main: true})
    },
  },

  actions: {
    async getProfileFromApi(ctx) {
      ctx.state.loadingProfile = true
      try {
        const response = await req.post('profile', {})
        if (response.data.obj.pid == 0) {
          router.push({ name: "Login" })
        } else {
          ctx.state.profile = response.data.obj
          ctx.state.contracts = response.data.contracts
          ctx.commit('genMenu')
          ctx.commit('genCards')
        }
      } catch (error) {
        console.log(error)
      }
      ctx.state.loadingProfile = false
    },
  },

  modules: {
  }
})
